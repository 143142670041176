// Generated by Framer (6597ba8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {O_q5I94fa: {hover: true}};

const cycleOrder = ["O_q5I94fa"];

const variantClassNames = {O_q5I94fa: "framer-v-18iqgza"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "O_q5I94fa", image: SztNMJ5OO = {src: new URL("assets/1024/7xkCsYbF1AuWOQIAwmsk3ix2coM.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/7xkCsYbF1AuWOQIAwmsk3ix2coM.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/7xkCsYbF1AuWOQIAwmsk3ix2coM.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/7xkCsYbF1AuWOQIAwmsk3ix2coM.jpg", import.meta.url).href} 2048w, ${new URL("assets/4096/7xkCsYbF1AuWOQIAwmsk3ix2coM.jpg", import.meta.url).href} 4096w, ${new URL("assets/7xkCsYbF1AuWOQIAwmsk3ix2coM.jpg", import.meta.url).href} 4640w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "O_q5I94fa", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const isDisplayed = () => {
if (gestureVariant === "O_q5I94fa-hover") return true
return false
}

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-DtOpx", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Image {...restProps} background={{alt: "", fit: "fill", ...toResponsiveImage(SztNMJ5OO)}} className={cx("framer-18iqgza", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"O_q5I94fa"} ref={ref} style={{borderBottomLeftRadius: 61, borderBottomRightRadius: 61, borderTopLeftRadius: 61, borderTopRightRadius: 61, ...style}} transition={transition} {...addPropertyOverrides({"O_q5I94fa-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-1p6dtuo"} layoutDependency={layoutDependency} layoutId={"O9gVkmF9i"} style={{backgroundColor: "rgba(66, 66, 66, 0.65)", borderBottomLeftRadius: 62, borderBottomRightRadius: 62, borderTopLeftRadius: 62, borderTopRightRadius: 62}} transition={transition}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TW9udHNlcnJhdC01MDA=", "--framer-font-family": "\"Montserrat\", serif", "--framer-font-size": "71px", "--framer-font-weight": "500", "--framer-letter-spacing": "1.3px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Birkaól</motion.p></React.Fragment>} className={"framer-1sz0akw"} fonts={["GF;Montserrat-500"]} layoutDependency={layoutDependency} layoutId={"VcpuGiaBS"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transition={transition} verticalAlignment={"center"} withExternalLayout/>)}</motion.div>)}</Image>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-DtOpx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DtOpx * { box-sizing: border-box; }", ".framer-DtOpx .framer-1t91tu4 { display: block; }", ".framer-DtOpx .framer-18iqgza { height: 558px; overflow: hidden; position: relative; width: 558px; will-change: transform; }", ".framer-DtOpx .framer-1p6dtuo { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; will-change: transform; }", ".framer-DtOpx .framer-1sz0akw { bottom: 166px; flex: none; height: 72px; left: calc(50.00000000000002% - 373px / 2); position: absolute; white-space: pre-wrap; width: 373px; word-break: break-word; word-wrap: break-word; }", ".framer-DtOpx .framer-v-18iqgza .framer-18iqgza { cursor: pointer; }", ".framer-DtOpx.framer-v-18iqgza.hover .framer-18iqgza { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 558px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 558
 * @framerIntrinsicWidth 558
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Md2_uSUpq":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"SztNMJ5OO":"image"}
 */
const FramerUIYPFBNAf: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerUIYPFBNAf;

FramerUIYPFBNAf.displayName = "ticker latest";

FramerUIYPFBNAf.defaultProps = {height: 558, width: 558};

addPropertyControls(FramerUIYPFBNAf, {SztNMJ5OO: {__defaultAssetReference: "data:framer/asset-reference,7xkCsYbF1AuWOQIAwmsk3ix2coM.jpg?originalFilename=3.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerUIYPFBNAf, [{family: "Montserrat", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/UIYPFBNAf:default", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-Y3tcoqK5.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-Y3tcoqK5.ttf", weight: "500"}])